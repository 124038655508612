<template>
  <div>
    <div class="dfc mb20">
      <p class="mr20">同步时间较长请耐心等待！为避免错误请勿反复刷新</p>
      <el-button size="mini" type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="plt section-wrapper mb20">
      <el-row :gutter="100" class="mb80">
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">网络状态</span>
            </div>
            <el-tag
              :type="tableData.networkStatus == 1 ? 'success' : 'danger'"
              effect="dark"
              v-if="tableData.networkStatus"
              >{{ $changeObj(STATUS)[tableData.networkStatus] }}</el-tag
            >
          </div>
        </el-col>
        <!--  -->
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">激活日期</span>
            </div>
            <el-tag
              type="success"
              effect="dark"
              v-if="tableData.activateDate"
              >{{ tableData.activateDate }}</el-tag
            >
          </div>
        </el-col>
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">服务期开始</span>
            </div>
            <el-tag
              type="success"
              effect="dark"
              v-if="tableData.serviceStartTime"
              >{{ tableData.serviceStartTime }}</el-tag
            >
          </div>
        </el-col>
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">服务期截止</span>
            </div>
            <el-tag
              type="success"
              effect="dark"
              v-if="tableData.serviceEndTime"
              >{{ tableData.serviceEndTime }}</el-tag
            >
          </div>
        </el-col>
      </el-row>
      <!--  -->
      <el-row :gutter="100">
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">可用流量(MB)</span>
            </div>
            <el-tag type="success" effect="dark" v-if="tableData.availableData"
              >{{ tableData.availableData }}MB</el-tag
            >
          </div>
        </el-col>
        <!--  -->
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">已用流量(MB)</span>
            </div>
            <el-tag type="success" effect="dark" v-if="tableData.usedData"
              >{{ tableData.usedData }}MB</el-tag
            >
          </div>
        </el-col>
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">流量套餐</span>
            </div>
            <el-tag type="success" effect="dark" v-if="tableData.dataInfo">{{
              tableData.dataInfo
            }}</el-tag>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="fd dfcc">
            <div class="dfc mb10">
              <span class="circle "></span>
              <span class="ml10">ICCID</span>
            </div>
            <el-tag type="success" effect="dark" v-if="tableData.ICCID">{{
              tableData.ICCID
            }}</el-tag>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="fe df mt20">
    </div> -->
  </div>
</template>

<script>
import { lotCardInfo } from "@/api/facility";
import { STATUS } from "@/js/constant";
export default {
  data() {
    return {
      STATUS,
      form: {
        haiLiDeviceNo: ""
      },
      tableData: []
    };
  },
  mounted() {
    this.lotCardInfo();
  },
  methods: {
    async lotCardInfo() {
      try {
        this.form.haiLiDeviceNo = this.$route.query.haiLiDeviceNo;
        const params = { ...this.form };
        const res = await lotCardInfo(params);
        this.tableData = res.mdata.lotCardInfo;
      } catch (e) {
        console.log(e, "e");
      }
    }
  },
  components: {}
};
</script>

<style scoped></style>
